<template>
  <div id="step-sidebar-page">
    <v-card-title class="primary--text font-weight-bold pa-0 mb-7">
        {{currentPage === 1 ? 'Select Matching Contact' : 'Selected Match'}}
    </v-card-title>
        <vue-perfect-scrollbar class="scroll-area" :settings="{'maxScrollbarLength': 60}">
        <template v-for="item in searchData">
            <v-card tile :key="item.id" class="mb-4">
                <div @click="selectDonor(item)" class="cursor-pointer d-flex justify-space-between align-center white--text primary font-weight-bold pa-1 pl-2 pr-2">
                    <div>{{`${item.name || ''}`}}</div>
                    <v-icon :color="selectedDonor && selectedDonor.id == item.id ? '#5CBBF6' : 'white'">mdi-check-circle-outline</v-icon>
                </div>
                <v-card-subtitle class="pa-3  pl-2 pr-2 dark--text" >
                    <div class="d-flex justify-space-between align-center">
                        <div v-if="item.mobile">{{item.mobile}}</div>
                        <div v-if="item.suburb || item.state">{{`${item.suburb ? item.suburb +', ' : ''}${item.state || ''}`}}</div>
                    </div>
                    <div>
                        {{item.email || ' - '}}
                    </div>
                </v-card-subtitle>
            </v-card>
        </template>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
export default {
    name: 'StepSidebar',
    props: ["currentPage"],
    emits: ["goHome"],
    data() {
        return {
        }
    },
    computed: {
        searchData: {
            get() {
                return this.$store.getters["donorSearchLists"];
            },
            set(newValue) {
                return this.$store.commit("setSearchLists", newValue);
            },
        },
        selectedDonor: {
            get() {
                return this.$store.getters["selectedDonor"];
            },
            set(newValue) {
                return this.$store.commit("setSelectedDonor", newValue);
            },
        },
    },
    methods: {
        selectDonor(item) {
            if (this.selectedDonor && this.selectedDonor.id === item.id) {
                const emptyItem = this.$helpers.cloneData(item);
                Object.keys(emptyItem).map(key => emptyItem[key] = '');
                emptyItem.phoneErr =  emptyItem.mobileErr = false;
                this.$store.commit("updateModel", emptyItem);
                this.selectedDonor = {};
                if (this.currentPage !== 1) {
                    this.$emit('goHome');
                }
            } else {
                this.searchData = this.searchData.filter(data => data.id === item.id);
                this.selectedDonor = item;
                this.$store.commit("updateModel", item);
                this.$emit('next');
            }
        }
    }
};
</script>

<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  height: 400px;
}
</style>
